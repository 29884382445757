@import "variables";
@import "if";
@import "mixins";

@keyframes maintaco {
	0% {
		transform: translateX(-93.75em) rotate(0deg);
	}
	60% {
		transform: translateX(0em) rotate(360deg);
	}
	80% {
		transform: scale(3);
		filter: brightness(3);
	}
	100% {
		transform: scale(2);
		filter: brightness(2);
	}
}
.wrapper {
	max-width: 100em;
	width: 90%;
	margin-left: 5%;
}
#maintaco {
	animation: maintaco 3s ease-in 1 0s;
	padding-bottom: 5em;
}
.right {
	float: right;
	margin-left: .75em;
	max-width: 9em;
}
.logo {
	width: 5.25em;
	float: left;
	margin-right: 2.35em;
	padding-bottom: .25em;
}
.pageheader {
	font-size: 2em;
	font-family: 'Acme', sans-serif;
	display: block;
	padding-top: .5em;
	padding-left: 2.35em;
}
header {
	background-color: $color-main;
	color: white;
	width: 100%;
}
footer {
	font-size: 1em;
	position: fixed;
 	right: 0;
 	left: 0;
  	bottom: 0;
  	height: 2.8em;
  	background-color: $color-main;
  	text-align: center;
  	border-top: .1em black solid;
  	clear: both;
} 
.form {
	background-color: $color-main;
	margin: 0 auto;
	margin-top: 1em;
	border-radius: $border-radius-medium;
	margin-bottom: 3em;
	padding: .7em;
	color: $color-accent;
	h2, h3 {
		color: $color-titles;
		padding-top: var(--standard-padding);
		padding-bottom: calc(var(--standard-padding)*.5);
		margin-top: var(--standard-margin);
		margin-bottom: calc(var(--standard-margin)*.5);
	}
	*:focus {
		border-color: $color-complement;
		box-shadow: 0 0 1em $color-accent;
	}
	legend, label {
		color: darken($color-main, 40%);
	}
}
.normalaside {
	@extend .form;
	padding: .5em;
	color: darken($color-main, 40%);
	h2 {
		padding: 0;
		margin: 0;
	}
	ul {
	  list-style: none;
	  padding: 0;
	}
	li {
  		padding-left: 1.3em;
  		padding-bottom: 1em;
  		a {
  			color: darken($color-complement, 40%);
  		}
		&:before {
		  content: "\1F32E"; 
		  font-family: FontAwesome;
		  margin-left: -1.3em; 
		  width: 1.3em; 
		  display: inline-block;
		  padding-right: .7em;
		}
	}
}	
nav a:hover {
	color: black;
	background-color: $color-accent;
	border-radius: $border-radius-small;
	width: 100%;
}
nav {
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}
	li {
		display: inline-block;
	}
	a {
		color: black;
		font-family: 'Lato', sans-serif;
		font-weight: bold;
		text-decoration: none;
		//padding: 0.2em 0.5em;
	}
	a:nth-type(n+1) {
		margin-left: 1em;
	}
}
.uk-navbar {
	display: block;
	background-color: #ae9984;
  	width: 100%;
}
table {
	padding: .35em;
	margin: 0 auto;
	caption {
		font-family: 'Acme', sans-serif;
		@include big-and-bold;
	}
}	
thead th {
	background: lighten($color-main, 20%);
	font-family: 'Lato', sans-serif;
	@include big-and-bold;
}
tbody th {
	text-align: left;
	font-weight: bold;
}
th, td {
	padding-left: 1em;
	padding-right: 1em;
	margin: 1em;
	font-size: 0.8em;
}
tr {
	background-color: lighten($color-complement, 20%);
	&:nth-child(odd) {
		background-color: lighten($color-complement, 30%);
	}
}
button {
	margin-top: 1em;
}
.tacoback {
	background: url(images/plate_800.png) no-repeat;
}
svg {
	padding-left: 8em;
}
.viewport:hover path[id^='lettuce'], .viewport:hover path[id^='meat'], .viewport:hover path[id^='cheese'], .viewport:hover path[id^="tomato"] {
	animation: toppings 2s ease-in 1 0s;
}
#tacosvg {
	width: 31.25em;
}
@keyframes toppings {
	from {
		transform: translateY(-31.25em);
	}
	to {
		transform: translateY(0em);
	}
}
svg {
	padding: 0;
}
.smallmenu {
	display: block;
	background-color: #F7F8F7;
}